.pokémons {
    &-header {

        & h1 {
            display: inline;
        }

        & .button-sort {
            float: right;
            margin-top: 0;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1em;
        padding: 0;
    }

    &-item {
        list-style: none;
        margin: .5em .2em;
        transition: box-shadow .2s;

        &:hover {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, .25);
        }

        &:active {
            transition: box-shadow .1s;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, .25), inset 0 0 12px 0 rgba(0, 0, 0, .25);
        }

        &:empty {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    .pokémons {
        text-align: center;

        &-header {

            & .button-sort {
                margin-top: 1em;
                float: none;
            }
        }
    }
}
