
.pokémon {
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    border-radius: .2em;
    padding: .5em;
    width: 200px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    cursor: pointer;

    &-name {
        margin: 0;
    }

    &-date {
        margin: 0;
    }

    &-type {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, .2);
        border-radius: .2em;
        text-shadow: 0 0 2px black;
        color: white;
        box-shadow: 0 0 2px rgba(0, 0, 0, .5);
    }
}

.my_pokémon {
    height: 232px;
}
