.footer {
    height: 5em;
    padding: 1em;
    background-color: #222;
    color: white;
    box-shadow: 4px 0 6px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: margin-left .5s;
}
