.menu {
    position: fixed;
    width: 11em;
    padding: 1em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    top: 0;
    background-color: white;
    height: 100%;
    transition: transform .5s;
    transform: translateX(-13em);

    & h1 {
        margin-top: .5em;
    }

    &-close {
        display: none;
        cursor: pointer;
        transition: transform .1s;
        padding: 1em;
        margin: -1em;

        &:hover {
            transform: scale(1.2);
        }

        &:active {
            transform: scale(.98);
        }
    }

    &-top {

    }

    &-bottom {
        bottom: 2em;
        position: absolute;
    }

    & ul {
        list-style-type: none;
        padding: 0;

        & li {
            list-style: none;
            padding: .5em;
            transition: transform .2s;
            cursor: pointer;

            & a {
                display: block;
            }

            & .active {
                font-weight: bold;
            }

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(.98);
            }
        }
    }
}
